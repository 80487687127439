<template>
    <div class="TargetUsageGridBox">
      <div class="text-right">
        <b-button type="button" class="mb-1 btn-light" @click="onExcelDownload()">다운로드</b-button>
      </div>
      <b-table
        head-variant="dark"
        show-empty
        hover
        responsive
        ref="TargetUsageGridBox"
        :style="'min-width:100%;'"
        :class="'text-center mb-0 rounded'"
        :items="items"
        :fields="fields"
        sticky-header="750px"
        :empty-text="$t('조회된 결과가 없습니다.')"
        table-variant="light"
        select-mode="multi"
        selectable
      >
        <template #empty="scope">
          <h4>{{ scope.emptyText }}</h4>
        </template>

        <template #cell(itemName)="row">
          {{row.item.itemName}}
        </template>

        <template #cell(m01)="row">

          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m01" />
          </div>  
          
          <div v-else>
            {{ row.item.m01 }}
          </div>  

        </template>

        <template #cell(m02)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m02" />
          </div>  
          
          <div v-else>
            {{ row.item.m02 }}
          </div>            
        </template>

        <template #cell(m03)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m03" />
          </div>  
          
          <div v-else>
            {{ row.item.m03 }}
          </div>            
        </template>    

        <template #cell(m04)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m04" />
          </div>  
          
          <div v-else>
            {{ row.item.m04 }}
          </div>            
        </template>    

        <template #cell(m05)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m05" />
          </div>  
          
          <div v-else>
            {{ row.item.m05 }}
          </div>            
        </template>    

        <template #cell(m06)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m06" />
          </div>  
          
          <div v-else>
            {{ row.item.m06 }}
          </div>            
        </template>    

        <template #cell(m07)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m07" />
          </div>  
          
          <div v-else>
            {{ row.item.m07 }}
          </div>            
        </template>    

        <template #cell(m08)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m08" />
          </div>  
          
          <div v-else>
            {{ row.item.m08 }}
          </div>            
        </template>    

        <template #cell(m09)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m09" />
          </div>  
          
          <div v-else>
            {{ row.item.m09 }}
          </div>            
        </template>    

        <template #cell(m10)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m10" />
          </div>  
          
          <div v-else>
            {{ row.item.m10 }}
          </div>            
        </template>    

        <template #cell(m11)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m11" />
          </div>  
          
          <div v-else>
            {{ row.item.m11 }}
          </div>            
        </template>    

        <template #cell(m12)="row">
          <div v-if="row.item.btnProc == 'Save'">
            <input type="text" class="form-control text-right" v-model= "row.item.m12" />
          </div>  
          
          <div v-else>
            {{ row.item.m12 }}
          </div>            
        </template>    

        <template #cell(btnProc)="row">
          <div v-if="row.item.btnProc == 'Apply'" class="d-flex flex-row">
              <b-button @click="onApply(row.item)" class="m-r-5" :style="{width:'60px'}">적용</b-button>
          </div>

          <div v-else-if="row.item.btnProc == 'Save'" class="d-flex flex-row">
              <b-button @click="onSave(row.item)" class="m-r-5" :style="{width:'60px'}">저장</b-button>
          </div>

          <div v-else class="d-flex flex-row">
              
          </div>

        </template>    

      </b-table>
    </div>
  </template>

  <style scoped>
  .b-table-sticky-header {
    max-height: 760px;
  }
  </style>

<script>
//import XLSX from "xlsx";
import backEndApi from "../../api/backEndApi";
import * as popupMessages from "@src/consts/popupMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {},
  props: ["dataList"],
  data() {
    return {
      fields: null,
      items: null,
      emptyText: null,
      selectedRow: [],
      popupMessages
    };
  },
  watch: {
    propList() {
      this.setFields();
    },
    dataList() {
      this.setItems();
    },
  },
  created() {

    this.setFields();
    this.setItems();
  },
  mounted() { },

  methods: {

    setFields() { //그리드 Head column 생성  

      this.fields = [];
      this.fields.push({ key: "itemName", label: "항목명" });

      //12개월 Data column
      for (let i = 1; i < 13; i++) {

        let columnData = {
          key: "m" + i.toString().padStart(2, "0"),
          label: i.toString().padStart(2, "0") + " 월",
        };

        this.fields.push(columnData);
      }

      this.fields.push({ key: "btnProc", label: "" });
    },

    setItems() {

      this.items = [];

      for (const resultData of this.dataList) {

        let itemData = {};
        itemData.ptDvsn = resultData.ptDvsn;
        itemData.ptIdx = resultData.ptIdx;
        itemData.baseDate = resultData.baseDate;
        itemData.itemName = resultData.itemName;
        itemData.btnProc = resultData.btnProc;

        for (const monthData of resultData.dataList) {

          if (typeof (monthData.ptVal) == "number") {
            itemData["m" + monthData.regDt] = Number.parseFloat(monthData.ptVal).toFixed(1);

          } else {
            itemData["m" + monthData.regDt] = monthData.ptVal;
          }

        } // resultData.dataList For 

        this.items.push(itemData);

      } // props.dataList
    },

    async onSave(data) {

      let dataList = [];

      //기준년도 1월~12월 에너지 사용목표 데이터 생성 
      for (let i = 1; i < 13; i++) {

        let rawData = {
          "regDt": data.baseDate.substr(0, 4) + i.toString().padStart(2, "0"),
          "ptIdx": data.ptIdx,
          "ptVal": 0
        };

        //해당월 에너지 사용목표 입력값이 빈값, "-"아닐때만 입력값 data 처리 
        if ((!this.isEmpty(data["m" + i.toString().padStart(2, "0")])) && (data["m" + i.toString().padStart(2, "0")] != "-")) {
          rawData.ptVal = Number.parseFloat(data["m" + i.toString().padStart(2, "0")]);
        }

        dataList.push(rawData);
      } // for 13           


      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {

        await backEndApi.target.insertgetTargetData(dataList);
        this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);

        //emit 동작 안함 원인모름 : 확인 바람 
        //this.$emit("reSearch");


      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },

    onApply(data) {
      console.log("onApply:::", data);
    },
    onExcelDownload() {
      console.log("onExcelDownload:::::");
      /*
            var excelName = this.searchCond.timeForm + this.searchCond.timeDsvn + " 실내 환경 정보";
      
            this.alertQuestion("엑셀 다운로드 하시겠습니까?", excelName).then((result) => {
              if (!result.value) return;
      
              var excelData = XLSX.utils.table_to_sheet(document.getElementById("analysis-table")); // table id를 넣어주면된다
              var workBook = XLSX.utils.book_new(); // 새 시트 생성
      
              XLSX.utils.book_append_sheet(workBook, excelData, excelName); // 시트 명명, 데이터 지정
              XLSX.writeFile(workBook, excelName + ".xlsx"); // 엑셀파일 만듬
             
            });
             */
    },
  },
};
</script>