<template>
  <div class="TargetUsage col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="pull-left">
          <h3>
            에너지 목표관리 > 에너지 소비량 목표관리
          </h3>
        </div>
      </div>
    </div>

    <hr class="mt-3 mb-3" />

    <div class="col-11">
      <target-usage-searchBox @search="onSearch" @defaultSearch="defaultSearch"></target-usage-searchBox>
    </div>

    <div class="col-10">
      <target-usage-grid-box v-if="dataList !== null" :dataList="dataList"></target-usage-grid-box>
    </div>
  </div>
</template>
  
<script>
import backEndApi from "../../api/backEndApi";
import targetUsageSearchBox from "./TargetUsageSearchBox.vue";
import targetUsageGridBox from "./TargetUsageGridBox.vue";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
  name: "TargetUsage",
  data() {
    return {
      dataList: null,
      searchCond: null,
      popupMessages
    };
  },
  components: {
    targetUsageSearchBox,
    targetUsageGridBox,
  },
  methods: {
    async defaultSearch(param) {
      await this.onSearch(param);
    },
    async onSearch(param) {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        let result = await backEndApi.target.getTargetDataList(param);
        this.dataList = result.data;
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
    reSearch() {
      console.log("reSearch====================");
      //this.$refs.targetUsageSearchBox.onSearch();
    },
  },
};
</script>  