<template>
    <div class="TargetUsageComponent">
      <target-Usage></target-Usage>
    </div>
  </template>
  
  <script>
  import targetUsage from "./TargetUsage.vue";
  
  export default {
    name: "TargetUsageComponent",
    components: {
      targetUsage,
    },
    props: [],
  };
  </script>
  
  <style></style>
  