<template>
    <div class="TargetUsageSearchBox">
      <div class="row p-b-10" :style="{ justifyContent: 'start' }">
          <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
            <div class="pt-2 p-l-5">
              <span>기준연도</span>
            </div>
            <div class="p-l-5 p-r-5">
              <b-form-select v-model="searchCond.baseYear" :options="searchBaseYearList"></b-form-select>
            </div>
          </div>

          <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
            <div  class="pt-2 p-l-5">
              <span>에너지원</span>
            </div>
            <div class="p-l-5 p-r-5">
              <b-form-select v-model="searchCond.energySourceCode" :options="searchEnergySourceList"></b-form-select>
            </div>
          </div>
          <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
            <div class="d-flex" :style="{ padding: '5px', height:'34px', textAlign: 'center', backgroundColor: '#ffffff', alignItems:'center'}" style="border:2px solid #348fe2; border-radius: 10px!important;">
                <b-button squared class="fa-3x" variant="#ffffff" size="md" @click="onSearch()" :style="{ width:'35px', height: '100%', padding:0, color:'#348fe2'}" >
                <span>{{"조회"}}</span>
                </b-button> 
            </div>
        </div>
      </div>
    </div>
</template>
    
<script>
import backEndApi from "../../api/backEndApi";
import * as popupMessages from "@src/consts/popupMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  name: "TargetUsageSearchBox",
  data() {
    return {

      searchCond: {
        energySourceCode: null,
        baseYear: null,
      },

      searchBaseYearList: null,
      searchEnergySourceList: null,
      popupMessages
    };
  },

  async created() {
    await this.setDefaultSearch();
  },

  methods: {

    async setDefaultSearch() {

      this.searchBaseYearList = await this.makeYearBox();
      this.searchEnergySourceList = await this.makeEnergySourceBox();
      this.searchCond.energySourceCode = "Elec";
      this.searchCond.baseYear = parseInt(new Date().format("yyyy"));

      let param = {
        baseYear: this.searchCond.baseYear,
        energySourceCode: this.searchCond.energySourceCode
      }

      this.$emit("defaultSearch", param);
    },

    async makeYearBox() {

      let dataList = [];
      let defaultYear = parseInt(new Date().format("yyyy"));

      for (let i = defaultYear - 3; i <= defaultYear + 3; i++) {
        let data = {};
        data.value = i;
        data.text = i + "년";
        dataList.push(data);
      }

      return dataList;
    },

    async makeEnergySourceBox() {
      let dataList = [];

      // await backEndApi.publicCode.getSmallCodeList("energySourceCode").then(({ data }) => {

      //   if (!data.errno) {

      //     for (const codeData of data) {
      //       let cData = {};
      //       cData.value = codeData.smallCode;
      //       cData.text = codeData.smallCodeName;
      //       dataList.push(cData);
      //     }

      //   } else if (data.errno == 100 || data.errno == 107) {
      //     this.alertNoti(data.message);

      //   } else {
      //     console.log("error : " + data.error);
      //     console.log("errono : " + data.errno);
      //     console.log("message : " + data.message);
      //   }

      // });

      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.publicCode.getSmallCodeList("energySourceCode");
        const data = result.data;

        for (const codeData of data) {
          let cData = {};
          cData.value = codeData.smallCode;
          cData.text = codeData.smallCodeName;
          dataList.push(cData);
        }

      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }

      return dataList;
    },

    onSearch() {

      if (this.searchCond.baseYear === null) {
        this.alertNoti(popupMessages.ENERGY_MAIN_YEAR_VALID_POPUP_MESSAGE);
        return;
      }

      if (this.searchCond.energySourceCode === null) {
        this.alertNoti(popupMessages.ENERGY_MAIN_ENERGY_TYPE_VALID_POPUP_MESSAGE);
        return;
      }

      let params = {
        energySourceCode: this.searchCond.energySourceCode,
        baseYear: this.searchCond.baseYear,
      };

      this.$emit("search", params);
    }
  }
};
</script>